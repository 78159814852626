<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="commonArea.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="commonArea.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="Reservable *" />
      <BorderSelect
        v-model="commonArea.data.reserve"
        label="Reservable *"
        :options="commonArea.reservableList"
      />
      <FormError
        :show="commonArea.rules.reserve"
        message="Seleccione una opción"
      />
      <BasicLabel
        label="Límite de Días"
        v-if="commonArea.data.reserve === 'Sí'"
      />
      <BorderInput
        v-if="commonArea.data.reserve === 'Sí'"
        v-model="commonArea.data.limitDays"
        label="Límite de Días"
        @keyup.enter="onSave"
      />
      <FormError
        v-if="commonArea.data.reserve === 'Sí'"
        :show="commonArea.rules.limitDays"
      />
      <BasicLabel
        label="Días disponibles"
        v-if="commonArea.data.reserve === 'Sí'"
      />
      <BorderInput
        v-if="commonArea.data.reserve === 'Sí'"
        v-model="commonArea.data.availableDays"
        label="Días disponibles"
        @keyup.enter="onSave"
      />
      <FormError
        v-if="commonArea.data.reserve === 'Sí'"
        :show="commonArea.rules.availableDays"
      />
      <BasicLabel label="Costo" v-if="commonArea.data.reserve === 'Sí'" />
      <BorderInput
        v-if="commonArea.data.reserve === 'Sí'"
        v-model="commonArea.data.cost"
        label="Costo"
        type="number"
        @keyup.enter="onSave"
      />
      <FormError
        v-if="commonArea.data.reserve === 'Sí'"
        :show="commonArea.rules.cost"
      />
      <BasicLabel
        label="Aforo Máximo"
        v-if="commonArea.data.reserve === 'Sí'"
      />
      <BorderInput
        v-if="commonArea.data.reserve === 'Sí'"
        v-model="commonArea.data.aforo"
        label="Aforo Máximo"
        @keyup.enter="onSave"
      />
      <FormError
        v-if="commonArea.data.reserve === 'Sí'"
        :show="commonArea.rules.aforo"
      />
      <BasicLabel label="Ubicación" />
      <BorderInput
        v-model="commonArea.data.location"
        label="Ubicación"
        @keyup.enter="onSave"
      />
      <FormError :show="commonArea.rules.location" />
      <BasicLabel label="Descripción" />
      <OutlinedTextArea v-model="commonArea.data.description" />
      <FormError :show="commonArea.rules.description" />
    </div>
  </div>
  <MobileTable
    v-show="commonArea.data.reserve && commonArea.data.reserve === 'Sí'"
    v-model:selection="store.state.commonArea.selectedTurns"
    label="Definición de Días y Horarios Disponibles *"
    :columns="commonArea.columns"
    :data="store.state.commonArea.turns"
  />
  <DesktopTable
    v-show="commonArea.data.reserve && commonArea.data.reserve === 'Sí'"
    v-model:selection="store.state.commonArea.selectedTurns"
    label="Definición de Días y Horarios Disponibles *"
    :columns="commonArea.columns"
    :data="store.state.commonArea.turns"
  />
  <Actions
    :items="commonArea.actions"
    v-show="commonArea.data.reserve && commonArea.data.reserve === 'Sí'"
  />
  <div class="container">
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
  <BasicModal v-model:visible="commonArea.showDialog">
    <template v-slot:dialog>
      <BasicTitle title="Definición de Días y Horarios Disponibles" />
      <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      />

      <div class="container-form">
        <div class="checkbox-container">
          <div v-for="item in commonArea.days" :key="item">
            <Checkbox
              :id="item"
              :value="item"
              v-model="commonArea.turn.data.selectedDays"
            />
            <label :for="item">{{ item }}</label>
          </div>
        </div>
        <FormError
          :show="commonArea.turn.rules.selectedDays"
          message="Seleccione un día como mínimo"
        />
        <BasicLabel label="Hora Inicio *" />
        <BorderTime
          v-model="commonArea.turn.data.fromTime"
          label="Hora Inicio *"
        />
        <FormError
          :show="commonArea.turn.rules.fromTime"
          message="Seleccione una hora de inicio"
        />
        <BasicLabel label="Hora Fin *" />
        <BorderTime v-model="commonArea.turn.data.toTime" label="Hora Fin *" />
        <FormError
          :show="commonArea.turn.rules.toTime"
          message="Seleccione una hora de fin válida superior a la hora de inicio"
        />
        <BasicLabel label="Cantidad de Turnos" />
        <BorderInput
          v-model="commonArea.turn.data.quantity"
          label="Cantidad de Turnos"
          type="number"
          @keyup.enter="onSaveTurn"
        />
        <FormError
          :show="commonArea.turn.rules.quantity"
          message="Ingrese la cantidad de turnos"
        />
        <BasicLabel label="Máximo de reservas" />
        <BorderInput
          v-model="commonArea.turn.data.persons"
          label="Máximo de reservas"
          type="number"
          @keyup.enter="onSaveTurn"
        />
        <FormError :show="commonArea.turn.rules.persons" />
        <PrimaryButton label="Guardar" :click="onSaveTurn" />
      </div>
    </template>
  </BasicModal>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  openMode,
  actions,
  validation,
  currency,
  dates,
} from "../../constants";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    OutlinedTextArea,
    FormError,
    PrimaryButton,
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    BorderTime,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const commonArea = reactive({
      showDialog: false,
      data: {
        id: "",
        buildingId: store.state.general.selectedBuilding,
        name: "",
        reserve: "",
        cost: "",
        limitDays: "",
        availableDays: "",
        aforo: "",
        location: "",
        description: "",
      },
      rules: {
        name: false,
        reserve: false,
      },
      columns: [
        {
          field: "days",
          header: "Días",
          sort: true,
        },
        {
          field: "turn",
          header: "Turno",
          sort: true,
        },
        {
          field: "from",
          header: "Inicio",
          sort: true,
        },
        {
          field: "to",
          header: "Fin",
          sort: true,
        },
        {
          field: "limit",
          header: "Máximo de reservas",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: () => (commonArea.showDialog = true),
          hidden: true,
        },
        {
          name: "Eliminar",
          action: async () => {
            const selected = store.state.commonArea.selectedTurns.map(
              (item) => item.id
            );
            const turns = store.state.commonArea.turns.filter(
              (item) => !selected.includes(item.id)
            );
            await store.commit("setSelectedTurns", []);
            await store.commit("setTurns", turns);
          },
          hidden: true,
        },
      ],
      days: ["L", "M", "X", "J", "V", "S", "D"],
      turn: {
        data: {
          selectedDays: [],
          fromTime: "",
          toTime: "",
          persons: "",
          quantity: "",
        },
        rules: {
          selectedDays: false,
          fromTime: false,
          toTime: false,
          persons: false,
          quantity: false,
        },
      },
      reservableList: [
        { id: "Sí", name: "Sí" },
        { id: "No", name: "No" },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!commonArea.data.name) {
        commonArea.rules.name = true;
        valid = false;
      }
      if (!commonArea.data.reserve) {
        commonArea.rules.reserve = true;
        valid = false;
      }
      if (
        commonArea.data.reserve === "Sí" &&
        !store.state.commonArea.turns.length
      ) {
        alert("Debe insertar al menos un turno");
        valid = false;
      }

      return valid;
    };

    const validateTurn = () => {
      let valid = true;

      if (!commonArea.turn.data.selectedDays.length) {
        commonArea.turn.rules.selectedDays = true;
        valid = false;
      }
      if (!commonArea.turn.data.fromTime) {
        commonArea.turn.rules.fromTime = true;
        valid = false;
      }
      if (
        !commonArea.turn.data.toTime ||
        commonArea.turn.data.toTime === "24:00" ||
        commonArea.turn.data.toTime - commonArea.turn.data.fromTime < 0
      ) {
        commonArea.turn.rules.toTime = true;
        valid = false;
      }
      if (!commonArea.turn.data.quantity) {
        commonArea.turn.rules.quantity = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: commonArea.data.id,
        buildingId: store.state.general.selectedBuilding,
        name: commonArea.data.name,
        indReserve: commonArea.data.reserve === "Sí" ? true : false,
        cost: commonArea.data.cost ? commonArea.data.cost : 0,
        limitDays: commonArea.data.limitDays ? commonArea.data.limitDays : 0,
        availableDays: commonArea.data.availableDays ? commonArea.data.availableDays : 0,
        aforo: commonArea.data.aforo ? commonArea.data.aforo : 0,
        location: commonArea.data.location,
        description: commonArea.data.description,
        turns: store.state.commonArea.turns,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.commonAreaActions.update,
          request
        );
      } else {
        response = await store.dispatch(
          actions.commonAreaActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedCommonAreas", []);
        store.commit("setTurns", []);
        router.push("/areascomunes/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const onSaveTurn = async () => {
      if (!validateTurn()) return;

      const diference = Math.round(
        (commonArea.turn.data.toTime - commonArea.turn.data.fromTime) /
          commonArea.turn.data.quantity
      );

      const turns = store.state.commonArea.turns;
      let length = 0;
      let fromTime = new Date(commonArea.turn.data.fromTime).getTime();

      while (length < commonArea.turn.data.quantity) {
        const obj = {
          days: commonArea.turn.data.selectedDays.join("/"),
          from: dates.timeToString(fromTime),
          fromTime,
          to: dates.timeToString(fromTime + diference),
          toTime: fromTime + diference,
          limit: commonArea.turn.data.persons || 0,
          number: length + 1,
          turn: `${length + 1} (${commonArea.turn.data.selectedDays.join(
            "/"
          )})`,
          id: validation.getRndInteger(1, 1000),
        };

        turns.push(obj);
        fromTime = obj.toTime;
        length++;
      }

      await store.commit("setTurns", turns);
      commonArea.showDialog = false;
      commonArea.turn.data.selectedDays = [];
      commonArea.turn.data.fromTime = "";
      commonArea.turn.data.toTime = "";
      commonArea.turn.data.persons = "";
      commonArea.turn.data.quantity = "";
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      if (!store.state.openMode) router.push("/areascomunes/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.commonArea.selectedCommonAreas[0];
        commonArea.data.id = selected.id;
        commonArea.data.name = selected.name;
        commonArea.data.reserve = selected.reserve;
        commonArea.data.cost = selected.cost;
        commonArea.data.limitDays = selected.limitDays;
        commonArea.data.availableDays = selected.availableDays;
        commonArea.data.aforo = selected.aforo;
        commonArea.data.location = selected.location;
        commonArea.data.description = selected.description;
        await store.commit("setTurns", selected.turns);
      } else {
        await store.commit("setTurns", []);
      }
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (commonArea.data.name) commonArea.rules.name = false;
      if (commonArea.data.reserve) commonArea.rules.reserve = false;
      if (commonArea.turn.data.selectedDays.length)
        commonArea.turn.rules.selectedDays = false;
      if (commonArea.turn.data.fromTime) commonArea.turn.rules.fromTime = false;
      if (commonArea.turn.data.toTime) commonArea.turn.rules.toTime = false;
      if (commonArea.turn.data.quantity) commonArea.turn.rules.quantity = false;
    });

    return {
      store,
      commonArea,
      onSave,
      onSaveTurn,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  gap: 0.8rem;
  font-family: "klavika";
  color: var(--primary);
}
.checkbox-container > div > label {
  margin-left: 0.5rem;
}
</style>
